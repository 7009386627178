// src/pages/FreeKeywordResearch.js
import React, { useState } from 'react';
import axiosInstance from '../services/axiosInstance';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiSearch, FiStar, FiEdit3, FiHash } from 'react-icons/fi';
import { FaRocket, FaClock } from 'react-icons/fa';
import Menu from '../components/HomeMenu';
import Footer from '../components/Footer';

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
};

const FreeKeywordResearch = () => {
  const [topic, setTopic] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [resultsLoaded, setResultsLoaded] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setKeywords([]);
    setError('');
    setLoading(true);
    
    try {
      console.log('Enviando requisição para /public/generate-keywords com:', { topic });
      const response = await axiosInstance.post('/public/generate-keywords', {
        topic
      });
      
      console.log('Resposta completa da API:', response.data); // Log detalhado
  
      if (response.data.keywords) {
        console.log('Keywords recebidas:', response.data.keywords);
        setKeywords(response.data.keywords);
        setResultsLoaded(true);
      } else {
        console.error('Resposta sem array de keywords:', response.data);
        setError('Could not generate keywords. Please try again.');
      }
    } catch (err) {
      console.error('Erro completo na requisição:', {
        message: err.message,
        response: err.response?.data,
        stack: err.stack
      });
      setError('An error occurred while generating keywords. Please try again later.');
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Free Keyword Research Tool | Ranqio</title>
        <meta name="description" content="Generate relevant keywords for your content. Enter your topic and get SEO-optimized keyword suggestions." />
        <meta name="keywords" content="Free Keyword Research, SEO keywords, content strategy, Ranqio" />
      </Helmet>
      
      <Menu />

      <motion.section 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="section-padding bg-gradient-to-br from-purple-50 to-blue-50"
      >
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Hero Section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-500">
                Free Keyword Research
              </span>{' '}
              Tool
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Discover high-potential keywords for your content. Boost your SEO rankings with data-driven suggestions.
            </p>
          </motion.div>

          {/* Form Section */}
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            animate="show"
            className="max-w-3xl mx-auto"
          >
            <motion.form 
              variants={itemVariants}
              onSubmit={handleSubmit} 
              className="space-y-6 bg-white p-8 rounded-2xl shadow-xl border border-gray-100"
            >
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Content Topic</label>
                <input
                  type="text"
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  placeholder="e.g. Sustainable Fashion Trends"
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all"
                  required
                />
              </div>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                disabled={loading}
                className="w-full bg-gradient-to-r from-purple-600 to-blue-500 text-white px-6 py-4 rounded-xl font-semibold hover:shadow-lg transition-all disabled:opacity-50"
              >
                {loading ? 'Analyzing Topic...' : 'Generate Keywords'}
              </motion.button>
            </motion.form>

            {/* Error & Result Display */}
            <motion.div variants={itemVariants} className="mt-8">
              {error && (
                <div className="p-4 bg-red-50 border border-red-200 rounded-xl text-red-700">
                  {error}
                </div>
              )}

              {keywords && keywords.length > 0 && (
                <motion.div 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="p-8 bg-white rounded-2xl shadow-lg border border-gray-100"
                >
                  <h2 className="text-2xl font-bold mb-6 text-gray-900">
                    Recommended Keywords
                  </h2>
                  <div className="grid gap-4">
                    {keywords.map((keywordString, index) => ( // Agora cada item é uma string
                      <div key={index} className="p-4 bg-gray-50 rounded-lg space-y-3">
                        <div className="flex items-center space-x-3">
                          <FiHash className="text-purple-600" />
                          <span className="font-medium">{keywordString}</span> {/* Mostra a string */}
                        </div>
                      </div>
                    ))}
                  </div>
                </motion.div>
              )}

              {/* Auto Pilot Upsell Section */}
              {resultsLoaded && (
                <motion.div 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mt-16 bg-gradient-to-br from-purple-50 to-blue-100 p-8 rounded-2xl border border-purple-100"
                >
                  <div className="max-w-4xl mx-auto text-center">
                    <div className="mb-8">
                      <h2 className="text-3xl font-bold mb-4">
                        Automate Your <span className="text-purple-600">Keyword Research</span>
                      </h2>
                      <p className="text-gray-600 text-lg">
                        Let Ranqio's Auto Pilot continuously find and track the best keywords for your content
                      </p>
                    </div>

                    <div className="grid md:grid-cols-2 gap-8 mb-12">
                      {[
                        {
                          icon: FaRocket,
                          title: "Auto Tracking",
                          text: "Monitor keyword rankings 24/7"
                        },
                        {
                          icon: FaClock,
                          title: "Save 10+ Hours/Month",
                          text: "Automatic updates and reporting"
                        }
                      ].map((feature, idx) => (
                        <motion.div 
                          key={idx}
                          whileHover={{ y: -5 }}
                          className="p-6 bg-white rounded-xl shadow-sm border border-gray-100"
                        >
                          <feature.icon className="w-8 h-8 text-purple-600 mb-4" />
                          <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                          <p className="text-gray-600">{feature.text}</p>
                        </motion.div>
                      ))}
                    </div>

                    <div className="space-y-6">
                      <motion.div 
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <Link
                          to="/signup"
                          className="inline-block bg-gradient-to-r from-purple-600 to-blue-500 text-white px-8 py-4 rounded-full font-semibold hover:shadow-lg transition-all"
                        >
                          Activate Auto Pilot Now
                        </Link>
                      </motion.div>
                      
                      <p className="text-gray-500 text-sm">
                        Free 7-day trial • No credit card required
                      </p>
                    </div>
                  </div>
                </motion.div>
              )}
            </motion.div>
          </motion.div>

          {/* Value Propositions */}
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, margin: "-100px" }}
            className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-20"
          >
            {[
              {
                icon: FiSearch,
                title: "Deep Analysis",
                text: "Examines search trends and competition data"
              },
              {
                icon: FiStar,
                title: "Relevance Score",
                text: "AI-powered relevance assessment for your topic"
              },
              {
                icon: FiEdit3,
                title: "Content Ideas",
                text: "Get inspired with ready-to-use content angles"
              },
              {
                icon: FiHash,
                title: "Long-Tail Keywords",
                text: "Identifies niche opportunities with less competition"
              }
            ].map((feature, idx) => (
              <motion.div
                key={idx}
                variants={itemVariants}
                className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow border border-gray-100"
              >
                <feature.icon className="w-8 h-8 text-purple-600 mb-4" />
                <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.text}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* How It Works */}
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="py-16"
          >
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-4">How It Works</h2>
              <p className="text-gray-600 max-w-xl mx-auto">
                Get keyword insights in three simple steps
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  step: "1",
                  title: "Enter Your Topic",
                  text: "Describe your content focus area or niche"
                },
                {
                  step: "2",
                  title: "AI Analysis",
                  text: "Our system analyzes search patterns and competition"
                },
                {
                  step: "3",
                  title: "Get Keywords",
                  text: "Receive actionable keyword recommendations"
                }
              ].map((step, idx) => (
                <motion.div
                  key={idx}
                  whileHover={{ y: -5 }}
                  className="p-8 bg-white rounded-xl shadow-lg border border-gray-100 text-center"
                >
                  <div className="w-12 h-12 bg-purple-100 text-purple-600 rounded-full flex items-center justify-center mb-6 mx-auto">
                    {step.step}
                  </div>
                  <h3 className="text-xl font-semibold mb-4">{step.title}</h3>
                  <p className="text-gray-600">{step.text}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </motion.section>

      {/* Final CTA */}
      <section className="bg-gradient-to-br from-purple-900 to-blue-900 text-white py-20">
        <div className="max-w-4xl mx-auto text-center px-4">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-3xl md:text-4xl font-bold mb-6"
          >
            Ready to Find Better Keywords?
          </motion.h2>
          <p className="text-lg text-gray-200 mb-8 max-w-xl mx-auto">
            Start optimizing your content strategy today - completely free
          </p>
          <motion.div 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to="/free-tools"
              className="inline-block bg-white text-purple-900 px-8 py-4 rounded-full font-semibold hover:shadow-xl transition-all"
            >
              Explore All Free Tools
            </Link>
          </motion.div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default FreeKeywordResearch;