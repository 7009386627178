// src/pages/FreeTools.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FiArrowRight, FiTool, FiBookOpen, FiSearch, FiFileText } from 'react-icons/fi';
import Menu from '../components/HomeMenu';
import Footer from '../components/Footer';

const toolCards = [
  {
    title: "SEO Strategy Generator",
    description: "Get a complete SEO strategy tailored to your business",
    icon: FiTool,
    link: "/free-seo-strategy"
  },
  {
    title: "Blog Post Outline",
    description: "Generate perfect blog post structures in seconds",
    icon: FiFileText,
    link: "/free-blog-outline"
  },
  {
    title: "Blog Post Title",
    description: "Create catchy blog post titles that rank",
    icon: FiBookOpen,
    link: "/free-blog-title"
  },
  {
    title: "Keyword Research",
    description: "Find high-potential keywords for your niche",
    icon: FiSearch,
    link: "/free-keyword-research"
  }
];

const FreeTools = () => {
  return (
    <>
      <Helmet>
        <title>Free SEO Tools | Ranqio</title>
        <meta name="description" content="Access our suite of free SEO tools including strategy generator, blog post tools, and keyword research." />
      </Helmet>
      
      <Menu />
      
      <section className="section-padding bg-gradient-to-br from-purple-50 to-blue-50">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <motion.h1
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-4xl md:text-5xl font-bold mb-6"
            >
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-500">
                Free SEO Tools
              </span>
            </motion.h1>
            
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Powerful tools for founders and marketers. Boost your SEO without the complexity.
            </p>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
            {toolCards.map((tool, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow p-6"
              >
                <div className="flex flex-col h-full">
                  <div className="mb-4">
                    <tool.icon className="w-8 h-8 text-purple-600 mb-4" />
                    <h3 className="text-xl font-bold text-gray-900 mb-2">{tool.title}</h3>
                    <p className="text-gray-600">{tool.description}</p>
                  </div>
                  <div className="mt-auto">
                    <Link
                      to={tool.link}
                      className="inline-flex items-center text-purple-600 hover:text-purple-700 font-medium"
                    >
                      Use Tool
                      <FiArrowRight className="ml-2" />
                    </Link>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default FreeTools;