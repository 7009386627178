import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import usePageTracking from './usePageTracking';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import KeywordList from './pages/KeywordList';
import BlogPostList from './pages/BlogPostList';
import BlogPostDetail from './pages/BlogPostDetail';
import Onboarding from './pages/Onboarding';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import OAuthCallbackHandler from './components/OAuthCallbackHandler';
import Pricing from './pages/Pricing';
import CheckoutSuccess from './pages/CheckoutSuccess';
import Profile from './pages/Profile';
import Education from './pages/Education';
import AutoPilot from './pages/AutoPilot';
import SeoGuideEbook from './lps/seoGuideEbook';
import { Toaster } from 'react-hot-toast'; // Add this import
import FreeSeoStrategy from './lps/FreeSeoStrategy';
import FreeTools from './pages/FreeTools';
import FreeKeywordResearch from './lps/FreeKeywordResearch';
import FreeBlogTitleGenerator from './lps/FreeBlogTitleGenerator';
import BlogPostOutline from './lps/BlogPostOutline';

function App() {

  return (
    <Router>
      <AuthProvider>
        <PageTracker>
          <div className="App">
            {/* Add the Toaster component here to enable toast notifications */}
            <Toaster position="top-center" />
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/oauth-callback" element={<OAuthCallbackHandler />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/seo-guide-ebook" element={<SeoGuideEbook />} />
              <Route path="/free-seo-strategy" element={<FreeSeoStrategy />} />
              <Route path="/free-tools" element={<FreeTools />} />
              <Route path="/free-keyword-research" element={<FreeKeywordResearch />} />
              <Route path="/free-blog-title" element={<FreeBlogTitleGenerator />} />
              <Route path="/free-blog-outline" element={<BlogPostOutline />} />

              {/* Protected Routes */}
              <Route path="/keywords" element={<ProtectedRoute element={<KeywordList />} />} />
              <Route path="/blogposts" element={<ProtectedRoute element={<BlogPostList />} />} />
              <Route path="/blogpost/:id" element={<ProtectedRoute element={<BlogPostDetail />} />} />
              <Route path="/onboarding" element={<ProtectedRoute element={<Onboarding />} />} />
              <Route path="/checkout-success" element={<ProtectedRoute element={<CheckoutSuccess />} />} />
              <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
              <Route path="/how-grow" element={<ProtectedRoute element={<Education />} />} />
              <Route path="/autopilot" element={<ProtectedRoute element={<AutoPilot />} />} />
            </Routes>
          </div>
        </PageTracker>
      </AuthProvider>
    </Router>
  );
}

export default App;

// Define the PageTracker component within App.js
const PageTracker = ({ children }) => {
  usePageTracking();
  return children;
};