// FreeSeoStrategy.js
import React, { useState } from 'react';
import axiosInstance from '../services/axiosInstance';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiCheckCircle, FiBarChart2, FiClock, FiTool } from 'react-icons/fi';
import { FaRocket, FaClock } from 'react-icons/fa';
import Menu from '../components/HomeMenu';
import Footer from '../components/Footer';

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
};

const FreeSeoStrategy = () => {
  const [business, setBusiness] = useState('');
  const [segment, setSegment] = useState('');
  const [strategy, setStrategy] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [strategyLoaded, setStrategyLoaded] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStrategy('');
    setError('');
    setLoading(true);
    
    try {
      const response = await axiosInstance.post('/public/generate-seo-strategy', {
        business,
        segment
      });
      
      if (response.data.seo_strategy) {
        setStrategy(response.data.seo_strategy);
        setStrategyLoaded(true);
      } else {
        setError('Could not generate SEO strategy. Please try again.');
      }
    } catch (err) {
      console.error('Error generating SEO strategy:', err);
      setError('An error occurred while generating the SEO strategy. Please try again later.');
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Free SEO Strategy Tool | Ranqio</title>
        <meta name="description" content="Generate a personalized SEO strategy for free. Enter your business and market segment to discover how to boost your organic traffic." />
        <meta name="keywords" content="Free SEO Strategy, SEO free, SEO strategy, Ranqio, business, market segment" />
      </Helmet>
      
      <Menu />

      <motion.section 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="section-padding bg-gradient-to-br from-purple-50 to-blue-50"
      >
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Hero Section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-500">
                Free SEO Strategy
              </span>{' '}
              Generator
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Get a data-driven SEO strategy tailored to your business. Optimize your online presence in minutes.
            </p>
          </motion.div>

          {/* Form Section */}
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            animate="show"
            className="max-w-3xl mx-auto"
          >
            <motion.form 
              variants={itemVariants}
              onSubmit={handleSubmit} 
              className="space-y-6 bg-white p-8 rounded-2xl shadow-xl border border-gray-100"
            >
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Business Type</label>
                <input
                  type="text"
                  value={business}
                  onChange={(e) => setBusiness(e.target.value)}
                  placeholder="e.g. E-commerce Store"
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all"
                  required
                />
              </div>
              
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Market Segment</label>
                <input
                  type="text"
                  value={segment}
                  onChange={(e) => setSegment(e.target.value)}
                  placeholder="e.g. Sustainable Fashion"
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all"
                  required
                />
              </div>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                disabled={loading}
                className="w-full bg-gradient-to-r from-purple-600 to-blue-500 text-white px-6 py-4 rounded-xl font-semibold hover:shadow-lg transition-all disabled:opacity-50"
              >
                {loading ? 'Generating Your Strategy...' : 'Generate Free Strategy'}
              </motion.button>
            </motion.form>

            {/* Error & Result Display */}
            <motion.div variants={itemVariants} className="mt-8">
              {error && (
                <div className="p-4 bg-red-50 border border-red-200 rounded-xl text-red-700">
                  {error}
                </div>
              )}

              {strategy && (
                <motion.div 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="p-8 bg-white rounded-2xl shadow-lg border border-gray-100"
                >
                  <h2 className="text-2xl font-bold mb-6 text-gray-900">
                    Your Custom SEO Strategy
                  </h2>
                  <div className="prose prose-purple max-w-none text-gray-600 whitespace-pre-line">
                    {strategy}
                  </div>
                </motion.div>
              )}

              {/* Seção de Upsell do Auto Pilot (MANTIDO E ATUALIZADO) */}
              {strategyLoaded && (
                <motion.div 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mt-16 bg-gradient-to-br from-purple-50 to-blue-100 p-8 rounded-2xl border border-purple-100"
                >
                  <div className="max-w-4xl mx-auto text-center">
                    <div className="mb-8">
                      <h2 className="text-3xl font-bold mb-4">
                        Want to <span className="text-purple-600">Automate</span> Your SEO Strategy?
                      </h2>
                      <p className="text-gray-600 text-lg">
                        Let Ranqio's Auto Pilot implement this strategy automatically while you focus on your business
                      </p>
                    </div>

                    <div className="grid md:grid-cols-2 gap-8 mb-12">
                      {[
                        {
                          icon: FaRocket,
                          title: "Instant Implementation",
                          text: "Auto Pilot executes all strategy steps automatically"
                        },
                        {
                          icon: FaClock,
                          title: "Save 20+ Hours/Month",
                          text: "No manual research or content writing needed"
                        }
                      ].map((feature, idx) => (
                        <motion.div 
                          key={idx}
                          whileHover={{ y: -5 }}
                          className="p-6 bg-white rounded-xl shadow-sm border border-gray-100"
                        >
                          <feature.icon className="w-8 h-8 text-purple-600 mb-4" />
                          <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                          <p className="text-gray-600">{feature.text}</p>
                        </motion.div>
                      ))}
                    </div>

                    <div className="space-y-6">
                      <motion.div 
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <Link
                          to="/signup"
                          className="inline-block bg-gradient-to-r from-purple-600 to-blue-500 text-white px-8 py-4 rounded-full font-semibold hover:shadow-lg transition-all"
                        >
                          Activate Auto Pilot Now
                        </Link>
                      </motion.div>
                      
                      <p className="text-gray-500 text-sm">
                        Free 7-day trial • No credit card required
                      </p>
                    </div>
                  </div>
                </motion.div>
              )}
            </motion.div>
          </motion.div>

          {/* Value Propositions */}
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, margin: "-100px" }}
            className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-20"
          >
            {[
              {
                icon: FiCheckCircle,
                title: "Tailored Strategy",
                text: "Custom recommendations based on your business specifics"
              },
              {
                icon: FiBarChart2,
                title: "Data-Driven Insights",
                text: "Leverage AI-powered analysis for maximum impact"
              },
              {
                icon: FiClock,
                title: "Instant Results",
                text: "Generate actionable strategies in seconds"
              },
              {
                icon: FiTool,
                title: "Easy to Implement",
                text: "Clear steps you can apply immediately"
              }
            ].map((feature, idx) => (
              <motion.div
                key={idx}
                variants={itemVariants}
                className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow border border-gray-100"
              >
                <feature.icon className="w-8 h-8 text-purple-600 mb-4" />
                <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.text}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* How It Works */}
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="py-16"
          >
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-4">How It Works</h2>
              <p className="text-gray-600 max-w-xl mx-auto">
                Three simple steps to your personalized SEO strategy
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  step: "1",
                  title: "Describe Your Business",
                  text: "Provide basic details about your company and market"
                },
                {
                  step: "2",
                  title: "AI Analysis",
                  text: "Our system processes your information with advanced algorithms"
                },
                {
                  step: "3",
                  title: "Get Actionable Strategy",
                  text: "Receive ready-to-implement SEO recommendations"
                }
              ].map((step, idx) => (
                <motion.div
                  key={idx}
                  whileHover={{ y: -5 }}
                  className="p-8 bg-white rounded-xl shadow-lg border border-gray-100 text-center"
                >
                  <div className="w-12 h-12 bg-purple-100 text-purple-600 rounded-full flex items-center justify-center mb-6 mx-auto">
                    {step.step}
                  </div>
                  <h3 className="text-xl font-semibold mb-4">{step.title}</h3>
                  <p className="text-gray-600">{step.text}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </motion.section>

      {/* Final CTA */}
      <section className="bg-gradient-to-br from-purple-900 to-blue-900 text-white py-20">
        <div className="max-w-4xl mx-auto text-center px-4">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-3xl md:text-4xl font-bold mb-6"
          >
            Ready to Boost Your Organic Traffic?
          </motion.h2>
          <p className="text-lg text-gray-200 mb-8 max-w-xl mx-auto">
            Start implementing your personalized SEO strategy today - no credit card required
          </p>
          <motion.div 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <button
              onClick={() => window.scrollTo(0, 0)}
              className="bg-white text-purple-900 px-8 py-4 rounded-full font-semibold hover:shadow-xl transition-all"
            >
              Generate Free Strategy Now
            </button>
          </motion.div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default FreeSeoStrategy;