import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { FaCalendarAlt, FaCreditCard, FaTimes, FaCrown, FaRocket, FaEdit } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import axiosInstance from '../services/axiosInstance';
import { ReactComponent as Blob } from '../images/blob.svg';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    marketSegment: '',
    productService: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No authentication token found. Please log in.');
        }
        const response = await axiosInstance.get('/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserData(response.data);
        setFormData({
          name: response.data.name,
          marketSegment: response.data.marketSegment || '',
          productService: response.data.productService || ''
        });
      } catch (err) {
        console.error('Error fetching user profile:', err);
        setError(err.response?.data?.message || 'Failed to load profile data.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleSaveProfile = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axiosInstance.put('/user/update', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setUserData(prev => ({ ...prev, ...response.data }));
      setEditMode(false);
      alert('Profile updated successfully!');
    } catch (err) {
      console.error('Error updating profile:', err);
      alert(err.response?.data?.message || 'Failed to update profile.');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/signin');
  };

  const handleSubscribe = () => {
    navigate('/pricing');
  };

  const handleCancelSubscription = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found.');
      }
      const response = await axiosInstance.post(
        '/cancel-subscription',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(response.data.message || 'Subscription canceled successfully.');
      const refreshedResponse = await axiosInstance.get('/user', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserData(refreshedResponse.data);
    } catch (err) {
      console.error('Error canceling subscription:', err);
      alert(err.response?.data?.message || 'Failed to cancel subscription. Please try again.');
    }
  };

  const handleManagePayment = async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No authentication token found.');
      }
      const response = await axiosInstance.get('/customer-portal', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { url } = response.data;
      window.location.href = url;
    } catch (err) {
      console.error('Error accessing customer portal:', err);
      alert(err.response?.data?.message || 'Failed to access payment methods. Please try again.');
    }
  };

  if (isLoading) {
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-white">
          <div className="flex flex-col items-center">
            <div className="loader mb-4"></div>
            <p className="text-lg text-gray-700">Loading profile...</p>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  if (error) {
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-white">
          <p className="text-lg text-red-500">{error}</p>
        </div>
        <Footer />
      </>
    );
  }

  if (!userData) {
    return null;
  }

  const {
    name,
    email,
    subscriptionStatus,
    keywordSearchCount,
    blogPostCount,
    subscriptionAmount,
    subscriptionRenewalDate,
    marketSegment,
    productService,
  } = userData;

  const isTrial = subscriptionStatus === 'trial';
  const isPremium = subscriptionStatus === 'active';
  const maxKeywordSearch = isTrial ? 10 : 500;
  const maxBlogPosts = isTrial ? 5 : 100;

  return (
    <>
      <Menu />

      {/* Updated Hero Section */}
      <section className="relative bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white py-24 px-6 overflow-hidden">
        <div className="max-w-6xl mx-auto text-center relative z-10">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-bold mb-6 leading-tight"
          >
            Account <span className="gradient-text">Profile</span>
          </motion.h1>
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-xl text-gray-200 max-w-xl mx-auto leading-relaxed"
          >
            Manage your subscription and account details
          </motion.p>
        </div>
        <div className="absolute top-0 left-0 w-full h-full opacity-10">
          <Blob className="w-full h-full text-purple-300 animate-float" />
        </div>
      </section>

      {/* Profile Content */}
      <section className="py-16 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* User Info Card */}
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              className="bg-white rounded-2xl shadow-lg p-8 border border-gray-200"
            >
              <div className="flex justify-between items-center mb-8">
                <h3 className="text-2xl font-bold text-gray-900 flex items-center">
                  <FaRocket className="text-purple-500 mr-3" />
                  User Profile
                </h3>
                {!editMode && (
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setEditMode(true)}
                    className="text-purple-600 hover:text-purple-700 flex items-center"
                  >
                    <FaEdit className="mr-2" /> Edit
                  </motion.button>
                )}
              </div>
              
              <div className="space-y-6">
                <div>
                  <label className="block text-gray-600 mb-2 font-medium">Name</label>
                  {editMode ? (
                    <input
                      type="text"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                    />
                  ) : (
                    <p className="text-lg text-gray-800 bg-gray-50 p-4 rounded-xl">{name}</p>
                  )}
                </div>
                
                <div>
                  <label className="block text-gray-600 mb-2 font-medium">Email</label>
                  <p className="text-lg text-gray-800 bg-gray-50 p-4 rounded-xl">{email}</p>
                </div>

                <div>
                  <label className="block text-gray-600 mb-2 font-medium">Market Segment</label>
                  {editMode ? (
                    <input
                      type="text"
                      value={formData.marketSegment}
                      onChange={(e) => setFormData({ ...formData, marketSegment: e.target.value })}
                      className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                    />
                  ) : (
                    <p className="text-lg text-gray-800 bg-gray-50 p-4 rounded-xl">{marketSegment}</p>
                  )}
                </div>

                <div>
                  <label className="block text-gray-600 mb-2 font-medium">Product / Service</label>
                  {editMode ? (
                    <input
                      type="text"
                      value={formData.productService}
                      onChange={(e) => setFormData({ ...formData, productService: e.target.value })}
                      className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-purple-500"
                    />
                  ) : (
                    <p className="text-lg text-gray-800 bg-gray-50 p-4 rounded-xl">{productService}</p>
                  )}
                </div>

                {editMode && (
                  <div className="flex justify-end space-x-4 mt-6">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setEditMode(false)}
                      className="px-6 py-2 border border-gray-300 rounded-lg text-gray-600 hover:bg-gray-50"
                    >
                      Cancel
                    </motion.button>
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={handleSaveProfile}
                      className="px-6 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
                    >
                      Save Changes
                    </motion.button>
                  </div>
                )}
              </div>
            </motion.div>

            {/* Subscription Card */}
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              className="bg-white rounded-2xl shadow-lg p-8 border border-gray-200 relative"
            >
              {isPremium && (
                <div className="absolute top-0 right-0 bg-purple-500 text-white px-4 py-1 rounded-bl-xl text-sm font-bold">
                  Active
                </div>
              )}
              
              <h3 className="text-2xl font-bold mb-8 text-gray-900 flex items-center">
                <FaCrown className="text-yellow-500 mr-3" />
                Subscription
              </h3>

              <div className="space-y-6">
                <div className="bg-gradient-to-r from-purple-50 to-blue-50 p-6 rounded-xl">
                  <div className="flex justify-between items-center mb-4">
                    <span className="text-gray-600 font-medium">Plan Type</span>
                    <span className={`text-lg font-semibold ${isPremium ? 'text-green-600' : 'text-blue-600'}`}>
                      {isPremium ? 'Premium' : 'Trial'}
                    </span>
                  </div>
                  
                  <div className="flex justify-between items-center mb-4">
                    <span className="text-gray-600 font-medium">Keyword Searches</span>
                    <span className="text-gray-800 font-medium">
                      {keywordSearchCount}/{maxKeywordSearch}
                    </span>
                  </div>

                  <div className="flex justify-between items-center">
                    <span className="text-gray-600 font-medium">Blog Posts</span>
                    <span className="text-gray-800 font-medium">
                      {blogPostCount}/{maxBlogPosts}
                    </span>
                  </div>
                </div>

                {isPremium ? (
                  <>
                    <div className="space-y-4">
                      <div className="flex justify-between items-center bg-gray-50 p-4 rounded-xl">
                        <span className="text-gray-600">Renewal Date</span>
                        <span className="text-gray-800">
                          {moment(subscriptionRenewalDate).format('MMM D, YYYY')}
                        </span>
                      </div>
                      <div className="flex justify-between items-center bg-gray-50 p-4 rounded-xl">
                        <span className="text-gray-600">Monthly Cost</span>
                        <span className="text-gray-800">${subscriptionAmount}</span>
                      </div>
                    </div>

                    <div className="space-y-4 mt-8">
                      <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={handleManagePayment}
                        className="w-full flex items-center justify-center space-x-2 bg-gray-900 text-white py-3 px-6 rounded-xl font-medium"
                      >
                        <FaCreditCard className="w-5 h-5" />
                        <span>Manage Payment</span>
                      </motion.button>

                      <motion.button
                        whileHover={{ scale: 1.02 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={handleCancelSubscription}
                        className="w-full flex items-center justify-center space-x-2 bg-red-500 text-white py-3 px-6 rounded-xl font-medium"
                      >
                        <FaTimes className="w-5 h-5" />
                        <span>Cancel Subscription</span>
                      </motion.button>
                    </div>
                  </>
                ) : (
                  (keywordSearchCount >= maxKeywordSearch || blogPostCount >= maxBlogPosts) && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="mt-8 text-center"
                    >
                      <p className="text-gray-600 mb-6">
                        Upgrade to Premium for unlimited access
                      </p>
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        onClick={handleSubscribe}
                        className="bg-gradient-to-r from-purple-600 to-blue-500 text-white py-4 px-8 rounded-full text-lg font-semibold hover:shadow-xl transition-all w-full"
                      >
                        Upgrade Now
                      </motion.button>
                    </motion.div>
                  )
                )}
              </div>
            </motion.div>
          </div>

          {/* Logout Button */}
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="flex justify-center mt-12"
          >
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={handleLogout}
              className="bg-gradient-to-r from-red-500 to-orange-500 text-white py-3 px-8 rounded-full text-lg font-semibold hover:shadow-xl transition-all"
            >
              Log Out
            </motion.button>
          </motion.div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Profile;