import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { fetchBlogPosts } from '../services/api'; 
import { Link } from 'react-router-dom';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import { FaCalendarAlt, FaPenFancy } from 'react-icons/fa';

// Função para remover tags HTML
const stripHtmlTags = (html) => {
  if (!html) return '';
  return html.replace(/<[^>]+>/g, '');
};

const BlogPostList = () => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAllPosts = async () => {
      try {
        const result = await fetchBlogPosts();
        // Sort posts by createdAt in descending order
        const sortedPosts = result.data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setPosts(sortedPosts);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllPosts();
  }, []);

  if (isLoading) {
    return (
      <>
        <Menu />
        <div className="flex items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-white">
          <div className="flex flex-col items-center space-y-4">
            <motion.div
              animate={{ rotate: 360 }}
              transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
              className="w-12 h-12 border-4 border-purple-500 border-t-transparent rounded-full"
            />
            <p className="text-xl text-gray-700 font-medium">Loading blog posts...</p>
          </div>
        </div>
        <Footer />
      </>
    );
  }

  if (posts.length === 0) {
    return (
      <>
        <Menu />
        <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-purple-50 to-white text-center px-4 py-16">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className="mb-8 p-6 bg-white rounded-full shadow-lg"
          >
            <FaPenFancy className="w-16 h-16 text-purple-600" />
          </motion.div>
          <h3 className="text-3xl font-bold text-gray-900 mb-4">
            Start Your Content Journey
          </h3>
          <p className="text-lg text-gray-600 max-w-md mb-8">
            Create your first SEO-optimized blog post to drive organic traffic
          </p>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link
              to="/keywords"
              className="inline-block bg-gradient-to-r from-purple-600 to-blue-500 text-white py-3 px-8 rounded-full text-base font-medium shadow-lg hover:shadow-xl transition-all"
            >
              Explore Keywords
            </Link>
          </motion.div>
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <Menu />

      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white py-24 px-6 overflow-hidden">
        <div className="max-w-6xl mx-auto text-center relative z-10">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-4xl md:text-5xl font-bold mb-6 leading-tight"
          >
            Your <span className="gradient-text">SEO Content</span> Library
          </motion.h2>
          <motion.p 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="text-xl text-gray-200 max-w-xl mx-auto leading-relaxed"
          >
            AI-optimized posts driving organic growth
          </motion.p>
        </div>
      </section>

      {/* Posts Section */}
      <section className="py-16 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-6 lg:px-8">
          <motion.ul 
            initial="hidden"
            animate="visible"
            className="grid grid-cols-1 md:grid-cols-2 gap-8"
          >
            {posts.map((post, index) => (
              <motion.li
                key={post._id} /* Using the unique Mongo _id as a React key */
                variants={{
                  hidden: { opacity: 0, y: 20 },
                  visible: { opacity: 1, y: 0 }
                }}
                transition={{ delay: index * 0.1 }}
                className="group relative bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow border border-gray-200"
              >
                {/* 
                  Overlay is absolutely positioned. We add 'pointer-events-none'
                  so it won't block clicks on the underlying link/button.
                */}
                <div
                  className="absolute inset-0 bg-gradient-to-r from-purple-500 to-blue-500 
                             opacity-0 group-hover:opacity-10 transition-opacity
                             rounded-2xl pointer-events-none"
                />
                <div className="p-8">
                  {/* Title Link -- use post.post_id for the route */}
                  <Link 
                    to={`/blogpost/${post.post_id}`}
                    className="text-black no-underline hover:no-underline"
                  >
                    <motion.h3 
                      whileHover={{ x: 5 }}
                      className="text-2xl font-bold text-gray-900 mb-4 leading-tight 
                                 hover:text-purple-600 transition-colors"
                    >
                      {post.title}
                    </motion.h3>
                  </Link>

                  <div className="flex items-center text-gray-500 mb-6">
                    <FaCalendarAlt className="w-5 h-5 mr-2 text-purple-500" />
                    <span className="text-sm">
                      {new Date(post.createdAt).toLocaleDateString('en-US', {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                      })}
                    </span>
                  </div>

                  <p className="text-gray-600 leading-relaxed mb-8 line-clamp-3">
                    {stripHtmlTags(post.content).substring(0, 400)}...
                  </p>

                  {/* "Read More" Button -- also use post.post_id */}
                  <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                    <Link
                      to={`/blogpost/${post.post_id}`}
                      className="inline-flex items-center bg-gradient-to-r from-purple-600 to-blue-500 
                                 text-white py-2.5 px-6 rounded-full text-base font-medium 
                                 hover:shadow-lg transition-all no-underline"
                    >
                      Read More
                      <svg 
                        className="w-4 h-4 ml-2" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M14 5l7 7m0 0l-7 7m7-7H3" 
                        />
                      </svg>
                    </Link>
                  </motion.div>
                </div>
              </motion.li>
            ))}
          </motion.ul>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default BlogPostList;
