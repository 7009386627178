import React, { useState } from 'react';
import axiosInstance from '../services/axiosInstance';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiFileText, FiList, FiBookOpen, FiCheckSquare } from 'react-icons/fi';
import { FaRocket, FaClock } from 'react-icons/fa';
import Menu from '../components/HomeMenu';
import Footer from '../components/Footer';

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
};

const BlogPostOutline = () => {
  const [keyword, setKeyword] = useState('');
  const [outline, setOutline] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [resultsLoaded, setResultsLoaded] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setOutline(null);
    setError('');
    setLoading(true);
    
    try {
      const response = await axiosInstance.post('/public/generate-outline', {
        keyword
      });
      
      if (response.data.outline) {
        setOutline(response.data.outline);
        setResultsLoaded(true);
      } else {
        setError('Could not generate outline. Please try again.');
      }
    } catch (err) {
      console.error('Error generating outline:', err);
      setError('An error occurred while generating outline. Please try later.');
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Blog Post Outline Generator | Ranqio</title>
        <meta name="description" content="Generate perfect blog post structures. Get AI-powered outlines with introduction, main sections, and conclusion." />
        <meta name="keywords" content="Blog outline generator, content structure, post planning, Ranqio" />
      </Helmet>
      
      <Menu />

      <motion.section 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="section-padding bg-gradient-to-br from-purple-50 to-blue-50"
      >
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Hero Section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-500">
                Blog Post Outline
              </span>{' '}
              Generator
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Create perfect content structures with AI-powered outlines. Save time and improve your writing workflow.
            </p>
          </motion.div>

          {/* Form Section */}
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            animate="show"
            className="max-w-3xl mx-auto"
          >
            <motion.form 
              variants={itemVariants}
              onSubmit={handleSubmit} 
              className="space-y-6 bg-white p-8 rounded-2xl shadow-xl border border-gray-100"
            >
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Main Topic</label>
                <input
                  type="text"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  placeholder="e.g. Digital Marketing Strategies"
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all"
                  required
                />
              </div>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                disabled={loading}
                className="w-full bg-gradient-to-r from-purple-600 to-blue-500 text-white px-6 py-4 rounded-xl font-semibold hover:shadow-lg transition-all disabled:opacity-50"
              >
                {loading ? 'Generating Outline...' : 'Generate Outline'}
              </motion.button>
            </motion.form>

            {/* Results Section */}
            <motion.div variants={itemVariants} className="mt-8">
              {error && (
                <div className="p-4 bg-red-50 border border-red-200 rounded-xl text-red-700">
                  {error}
                </div>
              )}

            {outline && (
            <motion.div 
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="p-8 bg-white rounded-2xl shadow-lg border border-gray-100"
            >
                <h2 className="text-2xl font-bold mb-6 text-gray-900">
                Blog Post Structure
                </h2>
                
                {/* Introduction */}
                <div className="mb-8">
                <div className="flex items-start gap-3 mb-4"> {/* Alterado para items-start */}
                    <FiBookOpen className="text-purple-600 mt-1 flex-shrink-0" /> {/* Adicionado mt-1 */}
                    <h3 className="text-lg font-semibold">Introduction</h3>
                </div>
                <p className="text-gray-600 pl-9">{outline.introduction}</p> {/* Aumentado pl para alinhar */}
                </div>

                {/* Main Sections */}
                <div className="space-y-8">
                {outline.sections?.map((section, index) => (
                    <div key={index} className="border-l-2 border-purple-100 pl-6">
                    <div className="flex items-start gap-3 mb-3"> {/* Alterado para items-start */}
                        <FiList className="text-purple-600 mt-1 flex-shrink-0" /> {/* Adicionado mt-1 */}
                        <h3 className="text-lg font-semibold">{section.title}</h3>
                    </div>
                    <ul className="space-y-2 pl-9"> {/* Aumentado pl para alinhar */}
                        {section.content.map((point, idx) => (
                        <li key={idx} className="text-gray-600 flex items-start gap-2"> {/* Adicionado items-start */}
                            <FiCheckSquare className="text-purple-600 mt-1 flex-shrink-0" /> {/* Mantido mt-1 */}
                            <span className="flex-1">{point}</span>
                        </li>
                        ))}
                    </ul>
                    </div>
                ))}
                </div>

                {/* Conclusion */}
                <div className="mt-8 pt-6 border-t border-gray-100">
                <div className="flex items-start gap-3 mb-4"> {/* Alterado para items-start */}
                    <FiFileText className="text-purple-600 mt-1 flex-shrink-0" /> {/* Adicionado mt-1 */}
                    <h3 className="text-lg font-semibold">Conclusion</h3>
                </div>
                <p className="text-gray-600 pl-9">{outline.conclusion}</p> {/* Aumentado pl para alinhar */}
                </div>
            </motion.div>
            )}


              {/* Auto Pilot Upsell Section */}
              {resultsLoaded && (
                <motion.div 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mt-16 bg-gradient-to-br from-purple-50 to-blue-100 p-8 rounded-2xl border border-purple-100"
                >
                  <div className="max-w-4xl mx-auto text-center">
                    <div className="mb-8">
                      <h2 className="text-3xl font-bold mb-4">
                        Automate Your <span className="text-purple-600">Content Creation</span>
                      </h2>
                      <p className="text-gray-600 text-lg">
                        Let Ranqio's Auto Pilot turn these outlines into full blog posts
                      </p>
                    </div>

                    <div className="grid md:grid-cols-2 gap-8 mb-12">
                      {[
                        {
                          icon: FaRocket,
                          title: "Auto Writing",
                          text: "Generate complete articles from outlines"
                        },
                        {
                          icon: FaClock,
                          title: "Save 15+ Hours/Month",
                          text: "From outline to published post in minutes"
                        }
                      ].map((feature, idx) => (
                        <motion.div 
                          key={idx}
                          whileHover={{ y: -5 }}
                          className="p-6 bg-white rounded-xl shadow-sm border border-gray-100"
                        >
                          <feature.icon className="w-8 h-8 text-purple-600 mb-4" />
                          <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                          <p className="text-gray-600">{feature.text}</p>
                        </motion.div>
                      ))}
                    </div>

                    <div className="space-y-6">
                      <motion.div 
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <Link
                          to="/signup"
                          className="inline-block bg-gradient-to-r from-purple-600 to-blue-500 text-white px-8 py-4 rounded-full font-semibold hover:shadow-lg transition-all"
                        >
                          Activate Auto Pilot Now
                        </Link>
                      </motion.div>
                      
                      <p className="text-gray-500 text-sm">
                        Free 7-day trial • No credit card required
                      </p>
                    </div>
                  </div>
                </motion.div>
              )}
            </motion.div>
          </motion.div>

          {/* Value Propositions */}
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, margin: "-100px" }}
            className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-20"
          >
            {[
              {
                icon: FiFileText,
                title: "Structured Content",
                text: "Logical flow from introduction to conclusion"
              },
              {
                icon: FiList,
                title: "Detailed Sections",
                text: "3-5 main sections with key points"
              },
              {
                icon: FiBookOpen,
                title: "SEO Ready",
                text: "Optimized structure for search engines"
              },
              {
                icon: FiCheckSquare,
                title: "Actionable Points",
                text: "Clear subpoints for easy writing"
              }
            ].map((feature, idx) => (
              <motion.div
                key={idx}
                variants={itemVariants}
                className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow border border-gray-100"
              >
                <feature.icon className="w-8 h-8 text-purple-600 mb-4" />
                <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.text}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* How It Works */}
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="py-16"
          >
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-4">How It Works</h2>
              <p className="text-gray-600 max-w-xl mx-auto">
                Get professional outlines in three simple steps
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  step: "1",
                  title: "Enter Your Topic",
                  text: "Provide your main subject or keyword"
                },
                {
                  step: "2",
                  title: "AI Analysis",
                  text: "We analyze content patterns and best practices"
                },
                {
                  step: "3",
                  title: "Get Structure",
                  text: "Receive ready-to-use blog outline"
                }
              ].map((step, idx) => (
                <motion.div
                  key={idx}
                  whileHover={{ y: -5 }}
                  className="p-8 bg-white rounded-xl shadow-lg border border-gray-100 text-center"
                >
                  <div className="w-12 h-12 bg-purple-100 text-purple-600 rounded-full flex items-center justify-center mb-6 mx-auto">
                    {step.step}
                  </div>
                  <h3 className="text-xl font-semibold mb-4">{step.title}</h3>
                  <p className="text-gray-600">{step.text}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </motion.section>

      {/* Final CTA */}
      <section className="bg-gradient-to-br from-purple-900 to-blue-900 text-white py-20">
        <div className="max-w-4xl mx-auto text-center px-4">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-3xl md:text-4xl font-bold mb-6"
          >
            Ready to Streamline Your Writing?
          </motion.h2>
          <p className="text-lg text-gray-200 mb-8 max-w-xl mx-auto">
            Start creating better content faster - completely free
          </p>
          <motion.div 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to="/free-tools"
              className="inline-block bg-white text-purple-900 px-8 py-4 rounded-full font-semibold hover:shadow-xl transition-all"
            >
              Explore All Free Tools
            </Link>
          </motion.div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default BlogPostOutline;