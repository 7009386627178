import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';
import '../homepage.css';
import strugglingGuy from '../images/founder_struggling.png';
import ranqioVideo from '../images/ranqio.mp4';
import HomeMenu from '../components/HomeMenu';
import { ReactComponent as Blob } from '../images/blob.svg';
import { motion } from 'framer-motion';
import { FaClock, FaMoneyBillWave, FaHourglassHalf, FaChartLine, FaHandsHelping, FaBullseye, FaRocket } from 'react-icons/fa';

const Home = () => {
  return (
    <>
      <HomeMenu />

      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-purple-900 via-blue-900 to-indigo-900 text-white section-padding overflow-hidden">
        <div className="max-w-6xl mx-auto text-center relative z-10">
          <motion.h1 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-5xl md:text-6xl font-bold mb-8 leading-tight"
          >
            The <span className="gradient-text">Auto Pilot SEO</span><br />for Early-Stage Founders
          </motion.h1>
          <p className="text-xl text-gray-200 max-w-2xl mx-auto mb-12 leading-relaxed">
            Solofounder? Small team? Save your precious hours for building products and talking to customers. Let Ranqio handle your SEO in minutes.
          </p>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link
              to="/signup"
              className="inline-block bg-gradient-to-r from-blue-400 to-purple-500 text-white py-4 px-12 rounded-full text-lg font-semibold hover:shadow-xl transition-all duration-300"
              onClick={() => {/* tracking code */}}
            >
              Try it Free – No credit card required
            </Link>
          </motion.div>
        </div>
        <div className="absolute top-0 left-0 w-full h-full opacity-10">
          <Blob className="w-full h-full text-purple-300 animate-float" />
        </div>
      </section>

      {/* Problem Section */}
      <section className="section-padding bg-gradient-to-b from-white to-gray-50">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          <div className="space-y-8">
            <h2 className="text-4xl font-bold mb-6 text-gray-900 leading-tight">
              SEO Consumes Time That Founders Don’t Have
            </h2>
            <p className="text-lg text-gray-600 mb-10 leading-relaxed">
              As an early-stage founder, you juggle everything—product development, customer calls, raising capital, and more. Traditional SEO eats up hours you simply can’t spare.
            </p>
            <div className="space-y-6">
              {[
                { icon: FaClock, text: "Researching keywords, analyzing competitors, and blogging can feel endless." },
                { icon: FaHourglassHalf, text: "Traditional SEO often takes months to show results—time you don’t have." },
                { icon: FaChartLine, text: "Successful SEO demands specialized knowledge or expensive consultants." },
              ].map((item, idx) => (
                <motion.div 
                  key={idx} 
                  whileHover={{ scale: 1.02 }}
                  className="feature-card flex items-start p-6 bg-white rounded-xl shadow-sm hover:shadow-md"
                >
                  <item.icon className="text-purple-500 h-6 w-6 mr-4 mt-1 flex-shrink-0" />
                  <p className="text-gray-700 leading-relaxed">{item.text}</p>
                </motion.div>
              ))}
            </div>
          </div>
          <motion.div 
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="relative"
          >
            <img
              src={strugglingGuy}
              alt="Founder struggling with SEO tasks"
              className="w-full h-auto rounded-2xl shadow-xl"
            />
            <div className="absolute -bottom-8 -right-8 bg-white p-6 rounded-2xl shadow-lg">
              <div className="text-3xl font-bold text-purple-600">80%</div>
              <div className="text-gray-600">Time Saved</div>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Solution Section */}
      <section className="section-padding bg-gray-900 text-white">
        <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          <div className="relative group">
            <video
              src={ranqioVideo}
              controls
              className="w-full h-auto rounded-2xl shadow-2xl transform group-hover:scale-[1.02] transition-transform"
              autoPlay
              loop
              muted
            />
            <div className="absolute inset-0 border-2 border-white/10 rounded-2xl pointer-events-none" />
          </div>
          <div className="space-y-8">
            <h2 className="text-4xl font-bold mb-6 leading-tight">
              Introducing Ranqio:<br /><span className="gradient-text">SEO on Auto Pilot</span>
            </h2>
            <p className="text-lg text-gray-300 mb-10 leading-relaxed">
              Ranqio streamlines SEO for busy founders. Our innovative <strong>Auto Pilot</strong> mode handles keyword research, blog creation, and more. You can also manually pick keywords and let our AI write the post—either way, you gain powerful SEO content with minimal effort.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {[
                { icon: FaBullseye, title: "High-Impact Keywords", color: "bg-blue-500" },
                { icon: FaHandsHelping, title: "AI-Generated Content", color: "bg-purple-500" },
                { icon: FaRocket, title: "Focus on Growth", color: "bg-pink-500" },
                { icon: FaMoneyBillWave, title: "Boost Revenue", color: "bg-green-500" },
              ].map((item, idx) => (
                <motion.div 
                  key={idx} 
                  whileHover={{ y: -5 }}
                  className="p-6 bg-gray-800 rounded-xl hover:bg-gray-700 transition-colors"
                >
                  <div className={`${item.color} w-12 h-12 rounded-lg flex items-center justify-center mb-4`}>
                    <item.icon className="text-white h-6 w-6" />
                  </div>
                  <h3 className="text-lg font-semibold">{item.title}</h3>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Organic Growth Section */}
      <section className="section-padding bg-white">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-6 text-gray-900 leading-tight">
            Accelerate Organic Traffic,<br />
            <span className="gradient-text">Unlock Revenue</span>
          </h2>
          <p className="text-lg text-gray-600 mb-12 leading-relaxed max-w-2xl mx-auto">
            Tired of SEO that drags on for months? Let Ranqio put your startup’s growth on fast-forward. Start building an organic presence and watch leads—and revenue—grow.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { icon: FaHourglassHalf, title: "Save Time", text: "Quickly choose keywords or let Auto Pilot do all the legwork" },
              { icon: FaRocket, title: "Faster Results", text: "Generate multiple SEO-focused blog posts and see traffic sooner" },
              { icon: FaChartLine, title: "Sustain Momentum", text: "Auto Pilot keeps fresh content rolling out continuously" },
            ].map((item, idx) => (
              <motion.div 
                key={idx} 
                whileHover={{ scale: 1.05 }}
                className="p-8 bg-white rounded-2xl shadow-lg hover:shadow-xl transition-shadow"
              >
                <item.icon className="text-purple-500 h-12 w-12 mx-auto mb-6" />
                <h3 className="text-xl font-bold mb-4">{item.title}</h3>
                <p className="text-gray-600 leading-relaxed">{item.text}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="section-padding bg-gray-50">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-12 text-gray-900 leading-tight">
            3 Simple Steps to<br />
            <span className="gradient-text">SEO Success</span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { number: 1, title: "Pick or Let Auto Pilot Pick", text: "Either enter your own keyword or allow Ranqio’s Auto Pilot to pick high-potential ones for you." },
              { number: 2, title: "Generate Content with AI", text: "Let our AI draft an SEO-optimized blog post—no more blank page anxiety." },
              { number: 3, title: "Grow Your Startup", text: "Publish content, attract organic traffic, and focus on building your business." },
            ].map((item, idx) => (
              <motion.div 
                key={idx} 
                whileHover={{ scale: 1.02 }}
                className="p-8 bg-white rounded-2xl shadow-lg"
              >
                <div className="h-16 w-16 mx-auto mb-6 bg-gradient-to-r from-purple-500 to-blue-500 text-white rounded-full flex items-center justify-center text-2xl font-bold">
                  {item.number}
                </div>
                <h3 className="text-xl font-bold mb-4">{item.title}</h3>
                <p className="text-gray-600 leading-relaxed">{item.text}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="section-padding bg-white">
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-12 text-gray-900 leading-tight">
            Flexible Plans for<br />
            <span className="gradient-text">Growing Founders</span>
          </h2>
          <div className="flex flex-col md:flex-row items-center justify-center gap-8">
            {[
              { 
                title: "Free Trial",
                price: "0.00",
                features: ["10 Keyword Analyses", "5 AI-Generated Blog Posts", "Basic Support"],
                cta: "Start Free Trial",
                gradient: "from-gray-100 to-gray-50",
                border: "border-gray-200"
              },
              { 
                title: "Pro Plan",
                price: "49.90",
                features: ["Unlimited Keywords", "Unlimited Posts", "Auto Pilot Mode", "Priority Support"],
                cta: "Get Started",
                gradient: "from-purple-50 to-blue-50",
                border: "border-purple-200",
                popular: true
              }
            ].map((plan, idx) => (
              <motion.div 
                key={idx}
                whileHover={{ y: -10 }}
                className={`w-full max-w-md p-8 rounded-3xl bg-gradient-to-b ${plan.gradient} border ${plan.border} relative ${plan.popular ? 'shadow-xl' : 'shadow-lg'}`}
              >
                {plan.popular && (
                  <div className="absolute top-0 right-0 bg-purple-500 text-white px-4 py-1 rounded-bl-xl text-sm font-bold">
                    Most Popular
                  </div>
                )}
                <h3 className="text-2xl font-bold mb-4">{plan.title}</h3>
                <p className="text-4xl font-bold mb-6">
                  ${plan.price}<span className="text-xl text-gray-600">/month</span>
                </p>
                <ul className="text-left space-y-4 mb-8">
                  {plan.features.map((feature, fIdx) => (
                    <li key={fIdx} className="flex items-center">
                      <svg className="w-5 h-5 text-green-500 mr-3" fill="currentColor" viewBox="0 0 20 20">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
                <Link
                  to="/signup"
                  className={`inline-block w-full py-3 px-6 rounded-full text-base font-medium transition ${
                    plan.popular 
                      ? 'bg-gradient-to-r from-purple-500 to-blue-500 text-white hover:shadow-lg'
                      : 'bg-gray-900 text-white hover:bg-gray-800'
                  }`}
                >
                  {plan.cta}
                </Link>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="section-padding bg-gray-900 text-white">
        <div className="max-w-5xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-12 leading-tight">
            What Founders Are<br />
            <span className="gradient-text">Saying</span>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {[
              {
                text: "Ranqio’s Auto Pilot handled my SEO tasks while I focused on closing deals. I’ve saved days of work each month!",
                author: "Alex T., Tech Startup Founder"
              },
              {
                text: "I’m a solo founder with no time to learn SEO. With Ranqio, I just pick a keyword and generate a post in minutes!",
                author: "Jamie L., SaaS Entrepreneur"
              }
            ].map((testimonial, idx) => (
              <motion.div 
                key={idx}
                whileHover={{ scale: 1.02 }}
                className="p-8 bg-gray-800 rounded-2xl text-left"
              >
                <div className="text-3xl text-gray-500 mb-4">“</div>
                <p className="text-lg text-gray-200 mb-6 leading-relaxed">{testimonial.text}</p>
                <div className="text-purple-400 font-semibold">{testimonial.author}</div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section className="section-padding bg-gradient-to-br from-purple-900 to-blue-900 text-white text-center">
        <div className="max-w-4xl mx-auto">
          <h2 className="text-4xl font-bold mb-6 leading-tight">
            Ready to Put Your SEO<br />
            <span className="gradient-text">on Auto Pilot?</span>
          </h2>
          <p className="text-lg text-gray-200 mb-10 leading-relaxed max-w-xl mx-auto">
            Grow your organic traffic and free up time to build, sell, and connect with customers. With Ranqio, SEO is just a few clicks away.
          </p>
          <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
            <Link
              to="/signup"
              className="inline-block bg-white text-black py-4 px-12 rounded-full text-lg font-semibold hover:shadow-xl transition-all duration-300"
            >
              Try it Free – No credit card required
            </Link>
          </motion.div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Home;