// src/components/HomeMenu.js
import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FiMenu, FiX } from 'react-icons/fi';

const HomeMenu = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  return (
    <nav className="bg-black/90 backdrop-blur-sm border-b border-gray-800 py-3">
      <div className="container mx-auto px-6 lg:px-12 flex justify-between items-center">
        {/* Logo */}
        <motion.div whileHover={{ scale: 1.05 }}>
          <Link
            to="/"
            className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-blue-400 
              bg-clip-text text-transparent tracking-tight"
            style={{ fontFamily: 'Poppins, sans-serif' }}
          >
            RANQIO
          </Link>
        </motion.div>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex items-center space-x-4">
          <motion.div whileHover={{ scale: 1.05 }}>
            <Link
              to="/free-tools"
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors
                rounded-lg font-medium hover:bg-white/5"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Free Tools
            </Link>
          </motion.div>

          <motion.div whileHover={{ scale: 1.05 }}>
            <a
              href="/blog/"
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors
                rounded-lg font-medium hover:bg-white/5"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Blog
            </a>
          </motion.div>

          <motion.div whileHover={{ scale: 1.05 }}>
            <a
              href="/seo-guide-ebook"
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors
                rounded-lg font-medium hover:bg-white/5"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Free SEO Ebook
            </a>
          </motion.div>

          <motion.div whileHover={{ scale: 1.05 }}>
            <Link
              to="/signin"
              className="px-4 py-2 text-gray-300 hover:text-white transition-colors
                rounded-lg font-medium hover:bg-white/5"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Sign In
            </Link>
          </motion.div>

          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to="/signup"
              className="px-6 py-2.5 bg-gradient-to-r from-purple-600 to-blue-500 
                text-white rounded-full font-medium shadow-lg hover:shadow-purple-500/20
                transition-all"
              style={{ fontFamily: 'Poppins, sans-serif' }}
            >
              Try Free
            </Link>
          </motion.button>
        </div>

        {/* Mobile Menu Button */}
        <button
          className="lg:hidden p-2 text-gray-300 hover:text-white"
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        >
          {isMobileMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
        </button>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="lg:hidden fixed inset-0 top-16 bg-gray-900/95 backdrop-blur-sm z-50 overflow-y-auto">
            <div className="container mx-auto px-6 py-6">
              <div className="flex flex-col space-y-4">
                <Link
                  to="/free-tools"
                  className="px-4 py-3 text-gray-300 hover:text-white bg-gray-800/50 rounded-lg"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Free Tools
                </Link>
                
                <Link
                  to="/blog"
                  className="px-4 py-3 text-gray-300 hover:text-white bg-gray-800/50 rounded-lg"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Blog
                </Link>
                
                <Link
                  to="/seo-guide-ebook"
                  className="px-4 py-3 text-gray-300 hover:text-white bg-gray-800/50 rounded-lg"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Free SEO Ebook
                </Link>

                <Link
                  to="/signin"
                  className="px-4 py-3 text-gray-300 hover:text-white bg-gray-800/50 rounded-lg"
                  onClick={() => setIsMobileMenuOpen(false)}
                >
                  Sign In
                </Link>

                <div className="pt-4 border-t border-gray-800">
                  <Link
                    to="/signup"
                    className="w-full block text-center px-6 py-3 bg-gradient-to-r from-purple-600 to-blue-500 
                      text-white rounded-full font-medium"
                  >
                    Try Free
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default HomeMenu;