import React, { useState } from 'react';
import axiosInstance from '../services/axiosInstance';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FiEdit, FiBook, FiList, FiHash, FiCheckCircle } from 'react-icons/fi';
import { FaRocket, FaClock } from 'react-icons/fa';
import Menu from '../components/HomeMenu';
import Footer from '../components/Footer';

const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 }
};

const FreeBlogTitleGenerator = () => {
  const [keyword, setKeyword] = useState('');
  const [titles, setTitles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [resultsLoaded, setResultsLoaded] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTitles([]);
    setError('');
    setLoading(true);
    
    try {
      const response = await axiosInstance.post('/public/generate-titles', {
        keyword
      });
      
      if (response.data.titles) {
        setTitles(response.data.titles);
        setResultsLoaded(true);
      } else {
        setError('Could not generate titles. Please try again.');
      }
    } catch (err) {
      console.error('Error generating titles:', err);
      setError('An error occurred while generating titles. Please try again later.');
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Free Blog Title Generator | Ranqio</title>
        <meta name="description" content="Generate catchy blog post titles for your content. Enter your keyword and get SEO-optimized title suggestions." />
        <meta name="keywords" content="Free Blog Title Generator, Blog post ideas, content creation, Ranqio" />
      </Helmet>
      
      <Menu />

      <motion.section 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="section-padding bg-gradient-to-br from-purple-50 to-blue-50"
      >
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Hero Section */}
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-500">
                Free Blog Title
              </span>{' '}
              Generator
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Create compelling blog post titles that drive clicks and engagement. Optimize your content strategy with AI-powered suggestions.
            </p>
          </motion.div>

          {/* Form Section */}
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            animate="show"
            className="max-w-3xl mx-auto"
          >
            <motion.form 
              variants={itemVariants}
              onSubmit={handleSubmit} 
              className="space-y-6 bg-white p-8 rounded-2xl shadow-xl border border-gray-100"
            >
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Main Keyword</label>
                <input
                  type="text"
                  value={keyword}
                  onChange={(e) => setKeyword(e.target.value)}
                  placeholder="e.g. Digital Marketing Trends"
                  className="w-full px-4 py-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all"
                  required
                />
              </div>

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                type="submit"
                disabled={loading}
                className="w-full bg-gradient-to-r from-purple-600 to-blue-500 text-white px-6 py-4 rounded-xl font-semibold hover:shadow-lg transition-all disabled:opacity-50"
              >
                {loading ? 'Generating Titles...' : 'Generate Titles'}
              </motion.button>
            </motion.form>

            {/* Results Section */}
            <motion.div variants={itemVariants} className="mt-8">
              {error && (
                <div className="p-4 bg-red-50 border border-red-200 rounded-xl text-red-700">
                  {error}
                </div>
              )}

              {titles.length > 0 && (
                <motion.div 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="p-8 bg-white rounded-2xl shadow-lg border border-gray-100"
                >
                  <h2 className="text-2xl font-bold mb-6 text-gray-900">
                    Suggested Blog Titles
                  </h2>
                  <div className="grid gap-4">
                    {titles.map((title, index) => (
                      <div key={index} className="p-4 bg-gray-50 rounded-lg flex items-start space-x-4">
                        <FiCheckCircle className="text-purple-600 mt-1 flex-shrink-0" />
                        <div className="min-w-0">
                          {/* Ajuste Principal Aqui ↓ */}
                          <span className="text-base font-medium text-gray-900 break-words">
                            {title}
                          </span>
                          <div className="mt-2 flex items-center space-x-2 text-sm text-gray-500">
                            <span>{title.length} characters</span>
                            <span>•</span>
                            <span>SEO Score: {Math.min(100, Math.round((title.length / 70) * 100))}%</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </motion.div>
              )}

              {/* Auto Pilot Upsell Section */}
              {resultsLoaded && (
                <motion.div 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  className="mt-16 bg-gradient-to-br from-purple-50 to-blue-100 p-8 rounded-2xl border border-purple-100"
                >
                  <div className="max-w-4xl mx-auto text-center">
                    <div className="mb-8">
                      <h2 className="text-3xl font-bold mb-4">
                        Automate Your <span className="text-purple-600">Content Creation</span>
                      </h2>
                      <p className="text-gray-600 text-lg">
                        Let Ranqio's Auto Pilot generate complete blog posts from your titles
                      </p>
                    </div>

                    <div className="grid md:grid-cols-2 gap-8 mb-12">
                      {[
                        {
                          icon: FaRocket,
                          title: "Auto Writing",
                          text: "Generate full blog posts automatically"
                        },
                        {
                          icon: FaClock,
                          title: "Save 20+ Hours/Month",
                          text: "From title to published post in minutes"
                        }
                      ].map((feature, idx) => (
                        <motion.div 
                          key={idx}
                          whileHover={{ y: -5 }}
                          className="p-6 bg-white rounded-xl shadow-sm border border-gray-100"
                        >
                          <feature.icon className="w-8 h-8 text-purple-600 mb-4" />
                          <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                          <p className="text-gray-600">{feature.text}</p>
                        </motion.div>
                      ))}
                    </div>

                    <div className="space-y-6">
                      <motion.div 
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        <Link
                          to="/signup"
                          className="inline-block bg-gradient-to-r from-purple-600 to-blue-500 text-white px-8 py-4 rounded-full font-semibold hover:shadow-lg transition-all"
                        >
                          Activate Auto Pilot Now
                        </Link>
                      </motion.div>
                      
                      <p className="text-gray-500 text-sm">
                        Free 7-day trial • No credit card required
                      </p>
                    </div>
                  </div>
                </motion.div>
              )}
            </motion.div>
          </motion.div>

          {/* Value Propositions */}
          <motion.div 
            variants={containerVariants}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true, margin: "-100px" }}
            className="grid md:grid-cols-2 lg:grid-cols-4 gap-6 mt-20"
          >
            {[
              {
                icon: FiEdit,
                title: "Creative Titles",
                text: "Unique and attention-grabbing formulations"
              },
              {
                icon: FiHash,
                title: "SEO Optimized",
                text: "Search engine friendly structures"
              },
              {
                icon: FiList,
                title: "Multiple Variations",
                text: "Diverse options for different angles"
              },
              {
                icon: FiBook,
                title: "Length Analysis",
                text: "Optimal character count monitoring"
              }
            ].map((feature, idx) => (
              <motion.div
                key={idx}
                variants={itemVariants}
                className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow border border-gray-100"
              >
                <feature.icon className="w-8 h-8 text-purple-600 mb-4" />
                <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.text}</p>
              </motion.div>
            ))}
          </motion.div>

          {/* How It Works Section */}
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            className="py-16"
          >
            <div className="text-center mb-12">
              <h2 className="text-3xl font-bold mb-4">How It Works</h2>
              <p className="text-gray-600 max-w-xl mx-auto">
                Get perfect blog titles in three simple steps
              </p>
            </div>

            <div className="grid md:grid-cols-3 gap-8">
              {[
                {
                  step: "1",
                  title: "Enter Your Keyword",
                  text: "Start with your main topic or focus keyword"
                },
                {
                  step: "2",
                  title: "AI Analysis",
                  text: "Our system analyzes search trends and competition"
                },
                {
                  step: "3",
                  title: "Get Titles",
                  text: "Receive optimized title suggestions instantly"
                }
              ].map((step, idx) => (
                <motion.div
                  key={idx}
                  whileHover={{ y: -5 }}
                  className="p-8 bg-white rounded-xl shadow-lg border border-gray-100 text-center"
                >
                  <div className="w-12 h-12 bg-purple-100 text-purple-600 rounded-full flex items-center justify-center mb-6 mx-auto">
                    {step.step}
                  </div>
                  <h3 className="text-xl font-semibold mb-4">{step.title}</h3>
                  <p className="text-gray-600">{step.text}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </motion.section>

      {/* Final CTA */}
      <section className="bg-gradient-to-br from-purple-900 to-blue-900 text-white py-20">
        <div className="max-w-4xl mx-auto text-center px-4">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-3xl md:text-4xl font-bold mb-6"
          >
            Ready to Boost Your Content?
          </motion.h2>
          <p className="text-lg text-gray-200 mb-8 max-w-xl mx-auto">
            Start creating compelling content today - completely free
          </p>
          <motion.div 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link
              to="/free-tools"
              className="inline-block bg-white text-purple-900 px-8 py-4 rounded-full font-semibold hover:shadow-xl transition-all"
            >
              Explore All Free Tools
            </Link>
          </motion.div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default FreeBlogTitleGenerator;